// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInput_text_input_container__wVdUA {
	display: flex;
	padding: 0.625rem 0.875rem;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
	border-radius: 2rem;
	border: 1px solid var(--gray-300, #d0d5dd);
	background: var(--base-white, #fff);

	/* Shadow/xs */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.textInput_text_input_wrapper__fwTsg svg {
	width: 1.25rem;
	height: 1.25rem;
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: translateY(-50%);
}

.textInput_text_input_wrapper__fwTsg input,
.textInput_text_input_wrapper__fwTsg input::placeholder {
	color: var(--gray-500, #667085);

	/* Text md/Regular */
	font-family: Be Vietnam;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	/* 150% */
}

.textInput_text_input_container__wVdUA::placeholder {
	opacity: 0.4;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/textInput/textInput.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,0BAA0B;CAC1B,mBAAmB;CACnB,WAAW;CACX,mBAAmB;CACnB,mBAAmB;CACnB,0CAA0C;CAC1C,mCAAmC;;CAEnC,cAAc;CACd,kDAAkD;AACnD;;AAEA;CACC,cAAc;CACd,eAAe;CACf,kBAAkB;CAClB,QAAQ;CACR,WAAW;CACX,2BAA2B;AAC5B;;AAEA;;CAEC,+BAA+B;;CAE/B,oBAAoB;CACpB,uBAAuB;CACvB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,YAAY;AACb","sourcesContent":[".text_input_container {\n\tdisplay: flex;\n\tpadding: 0.625rem 0.875rem;\n\talign-items: center;\n\tgap: 0.5rem;\n\talign-self: stretch;\n\tborder-radius: 2rem;\n\tborder: 1px solid var(--gray-300, #d0d5dd);\n\tbackground: var(--base-white, #fff);\n\n\t/* Shadow/xs */\n\tbox-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\n}\n\n.text_input_wrapper svg {\n\twidth: 1.25rem;\n\theight: 1.25rem;\n\tposition: absolute;\n\ttop: 50%;\n\tright: 1rem;\n\ttransform: translateY(-50%);\n}\n\n.text_input_wrapper input,\n.text_input_wrapper input::placeholder {\n\tcolor: var(--gray-500, #667085);\n\n\t/* Text md/Regular */\n\tfont-family: Be Vietnam;\n\tfont-size: 1rem;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 1.5rem;\n\t/* 150% */\n}\n\n.text_input_container::placeholder {\n\topacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text_input_container": `textInput_text_input_container__wVdUA`,
	"text_input_wrapper": `textInput_text_input_wrapper__fwTsg`
};
export default ___CSS_LOADER_EXPORT___;
