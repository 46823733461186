// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardMain_dashboard_container__YtDCz {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2rem;
	padding: 0 2rem;
}

@media only screen and (max-width: 768px) {
	.dashboardMain_dashboard_container__YtDCz {
		padding: 0 1rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/layout/dashboardMain.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,SAAS;CACT,eAAe;AAChB;;AAEA;CACC;EACC,eAAe;CAChB;AACD","sourcesContent":[".dashboard_container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\tgap: 2rem;\n\tpadding: 0 2rem;\n}\n\n@media only screen and (max-width: 768px) {\n\t.dashboard_container {\n\t\tpadding: 0 1rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_container": `dashboardMain_dashboard_container__YtDCz`
};
export default ___CSS_LOADER_EXPORT___;
