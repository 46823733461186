// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptionContentWrapper_description_content_wrapper__NtUi5 {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4rem;
	position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/description/descriptionContentWrapper.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,SAAS;CACT,kBAAkB;AACnB","sourcesContent":[".description_content_wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tgap: 4rem;\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description_content_wrapper": `descriptionContentWrapper_description_content_wrapper__NtUi5`
};
export default ___CSS_LOADER_EXPORT___;
