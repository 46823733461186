import React, { useContext, useEffect, useState } from "react";
import styles from "./card.module.css"; // Replace with your actual CSS module path
import { useNavigate } from "react-router-dom";
import pokerbaazi from "../../../assets/PokerBaazi.svg";
import mpl from "../../../assets/MPL.jpg";
import junglePk from "../../../assets/jungleePoker.svg";
import Navbtn from "../../common/button/navbtn/navbtn";
import { UserContext } from "../../../App";

const OfferCard = ({ fillBtn, product }) => {
	const navigate = useNavigate();
	const { userData } = useContext(UserContext);
	const [mobile, setIsMobile] = useState(true);

	useEffect(() => {
		const width = window.innerWidth;
		setIsMobile(width <= 500);
		// eslint-disable-next-line
	}, []);

	const onJoinClick = (currentItem) => {
		if (!userData) {
			navigate("/");
		} else {
			localStorage.setItem("currentProductValue", JSON.stringify(product));
			localStorage.setItem("currentProduct", currentItem);
			navigate("/description");
		}
	};

	return (
		<div className={styles.card_item_container}>
			{/* Card Item Content */}
			<div className={styles.card_item_content}>
				{/* Item Content */}
				<div className={styles.item_content}>
					{/* Image */}
					<div className={styles.image}>
						{/* Image Content */}{" "}
						<img
							src={
								product && product.name && product.name === "Poker Baazi"
									? pokerbaazi
									: product && product.name && product.name === "Junglee Poker"
									? junglePk
									: mpl
							}
							alt=''
						/>{" "}
					</div>

					{/* Welcome Container */}
					<div className={styles.welcome_container}>
						{/* Welcome Content */}
						<div className={styles.welcome_content}>
							{/* Icon */}
							<div className={styles.icon}>
								{/* Icon Content */}
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='21'
									height='25'
									viewBox='0 0 21 25'
									fill='none'
								>
									<path
										d='M8.30894 9.82548C11.9167 7.81948 12.9535 5.62852 14.0977 0.961426C15.6926 5.86672 16.8975 7.71976 20.1277 9.27148C15.6147 12.4227 14.7021 14.5841 14.0977 18.6895C12.7011 13.7202 11.4426 11.7633 8.30894 9.82548Z'
										fill='#0D9488'
									/>
									<path
										d='M0.872502 19.311C3.03715 18.2411 3.65922 17.0726 4.34576 14.5835C5.30271 17.1997 6.02564 18.1879 7.96375 19.0155C5.25593 20.6962 4.70842 21.8489 4.34576 24.0385C3.50782 21.3882 2.75269 20.3445 0.872502 19.311Z'
										fill='#0D9488'
									/>
								</svg>
							</div>

							{/* Text */}
							<div className={styles.text}>
								{/* Text Content */}Welcome bonus up to <span>Rs. 20000</span>{" "}
							</div>
						</div>
					</div>

					{/* Item Info Container */}
					<div className={styles.item_info_container}>
						{/* Availability Container */}
						<div className={styles.availability_container}>
							{/* Available Content */}
							<div className={styles.available_content}>
								{/* Heading */}
								<div className={styles.heading}>{product && product.name}</div>

								{/* Tag 1 */}
								<div className={styles.tag1}>{/* Tag 1 Content */}T&C</div>

								{/* Tag 2 */}
								<div className={styles.tag2}>{/* Tag 2 Content */}A</div>
							</div>
							{/* Availability Text */}
							<div className={styles.availabilty_text}>
								<div>
									{/* Icon */}
									<div className={styles.icon}>
										{/* Icon Content */}
										{availabiltyicon}
									</div>

									{/* Text */}
									<div className={styles.text}>
										{/* Text Content */}Available for players from your country
									</div>
								</div>
								<div className={styles.text}>
									{product && product.smallDescription}
								</div>
							</div>
						</div>

						{/* Rating and Review Container */}
						<div className={styles.rating_and_review_container}>
							{/* Rating and Review */}
							<div className={styles.rating_and_review}>
								{/* Rating */}
								<div className={styles.rating}>
									{/* Rating Content */}
									{filstar} {filstar} {filstar} {blankstar} {blankstar}{" "}
								</div>

								{/* Text */}
								<div className={styles.text}>
									{/* Text Content */}
									{product && product.rating}
									<span> /5.0</span>{" "}
								</div>
							</div>
						</div>
					</div>

					<div style={{ width: "100%" }}>
						{fillBtn ? (
							<Navbtn
								text={userData ? "Join" : "Sign Up"}
								variant={"outlined_primary"}
								size={"small"}
								showIcon={false}
								style={{
									width: "100%",
								}}
								onClick={() => {
									onJoinClick(product._id);
								}}
								disabled={userData ? false : true}
							/>
						) : (
							<Navbtn
								text={userData ? "Join" : "Sign Up"}
								variant={"primary"}
								size={"small"}
								showIcon={false}
								style={{ width: "100%" }}
								onClick={() => {
									onJoinClick(product._id);
								}}
								disabled={userData ? false : true}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default OfferCard;
const availabiltyicon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='26'
		height='26'
		viewBox='0 0 26 26'
		fill='none'
	>
		<g clipPath='url(#clip0_5_20022)'>
			<path d='M-14 -7H40V6.33333H-14V-7Z' fill='#FF9933' />
			<path d='M-14 6.3335H40V19.6668H-14V6.3335Z' fill='white' />
			<path d='M-14 19.6665H40V32.9998H-14V19.6665Z' fill='#128807' />
			<path
				d='M13 18.3332C15.9823 18.3332 18.4 15.9454 18.4 12.9998C18.4 10.0543 15.9823 7.6665 13 7.6665C10.0176 7.6665 7.59998 10.0543 7.59998 12.9998C7.59998 15.9454 10.0176 18.3332 13 18.3332Z'
				fill='#000088'
			/>
			<path
				d='M13 17.6663C15.6096 17.6663 17.725 15.577 17.725 12.9997C17.725 10.4223 15.6096 8.33301 13 8.33301C10.3905 8.33301 8.27502 10.4223 8.27502 12.9997C8.27502 15.577 10.3905 17.6663 13 17.6663Z'
				fill='white'
			/>
			<path
				d='M12.9999 13.9331C13.5218 13.9331 13.9449 13.5152 13.9449 12.9997C13.9449 12.4843 13.5218 12.0664 12.9999 12.0664C12.478 12.0664 12.0549 12.4843 12.0549 12.9997C12.0549 13.5152 12.478 13.9331 12.9999 13.9331Z'
				fill='#000088'
			/>
			<path
				d='M17.6527 13.8485C17.7858 13.8658 17.9078 13.7733 17.9253 13.6419C17.9429 13.5105 17.8492 13.3899 17.7161 13.3726C17.5831 13.3553 17.461 13.4478 17.4435 13.5793C17.426 13.7107 17.5196 13.8312 17.6527 13.8485Z'
				fill='#000088'
			/>
			<path
				d='M12.9999 17.6665L13.1619 14.8665L12.9999 13.5332L12.8379 14.8665L12.9999 17.6665Z'
				fill='#000088'
			/>
			<path
				d='M17.2711 15.0094C17.3949 15.0599 17.537 15.0013 17.5886 14.8786C17.6403 14.7558 17.5818 14.6154 17.4581 14.565C17.3344 14.5145 17.1923 14.5731 17.1406 14.6959C17.089 14.8186 17.1474 14.959 17.2711 15.0094Z'
				fill='#000088'
			/>
			<path
				d='M11.7756 17.508L12.6663 14.8438L12.8614 13.5146L12.3559 14.7618L11.7756 17.508Z'
				fill='#000088'
			/>
			<path
				d='M16.5991 16.0328C16.7053 16.1132 16.8578 16.0929 16.9398 15.9875C17.0218 15.882 17.0022 15.7314 16.896 15.651C16.7898 15.5706 16.6373 15.5909 16.5553 15.6963C16.4733 15.8017 16.4929 15.9524 16.5991 16.0328Z'
				fill='#000088'
			/>
			<path
				d='M10.6365 17.0419L12.1946 14.6958L12.7307 13.4613L11.9157 14.5369L10.6365 17.0419Z'
				fill='#000088'
			/>
			<path
				d='M15.6817 16.8498C15.7632 16.9547 15.9157 16.9741 16.0224 16.8932C16.1292 16.8122 16.1497 16.6615 16.0682 16.5566C15.9868 16.4517 15.8343 16.4323 15.7275 16.5133C15.6208 16.5942 15.6003 16.7449 15.6817 16.8498Z'
				fill='#000088'
			/>
			<path
				d='M9.65823 16.3001L11.7776 14.432L12.6186 13.3762L11.5497 14.2068L9.65823 16.3001Z'
				fill='#000088'
			/>
			<path
				d='M14.5825 17.4034C14.6342 17.5262 14.7763 17.5848 14.9 17.5344C15.0236 17.484 15.082 17.3436 15.0303 17.2208C14.9787 17.0981 14.8365 17.0394 14.7128 17.0898C14.5892 17.1402 14.5308 17.2806 14.5825 17.4034Z'
				fill='#000088'
			/>
			<path
				d='M8.90843 15.3323L11.4444 14.0729L12.5316 13.2674L11.2813 13.794L8.90843 15.3323Z'
				fill='#000088'
			/>
			<path
				d='M13.3745 17.6574C13.3923 17.7892 13.5144 17.8822 13.6471 17.8652C13.7799 17.8482 13.873 17.7277 13.8552 17.5959C13.8373 17.4642 13.7153 17.3712 13.5825 17.3882C13.4498 17.4052 13.3567 17.5257 13.3745 17.6574Z'
				fill='#000088'
			/>
			<path
				d='M8.43628 14.2064L11.2164 13.6393L12.4778 13.1392L11.1317 13.3277L8.43628 14.2064Z'
				fill='#000088'
			/>
			<path
				d='M12.1399 17.5945C12.1227 17.7256 12.2169 17.8461 12.3503 17.8638C12.4837 17.8814 12.6057 17.7894 12.6229 17.6583C12.6401 17.5272 12.5459 17.4066 12.4125 17.389C12.2792 17.3714 12.1571 17.4634 12.1399 17.5945Z'
				fill='#000088'
			/>
			<path
				d='M8.27542 12.9946L11.1103 13.1565L12.4595 13.0052L11.1094 12.8471L8.27542 12.9946Z'
				fill='#000088'
			/>
			<path
				d='M10.9653 17.2185C10.9141 17.3408 10.9732 17.4812 11.0973 17.532C11.2215 17.5829 11.3636 17.525 11.4148 17.4026C11.466 17.2803 11.4069 17.1399 11.2828 17.0891C11.1587 17.0382 11.0165 17.0961 10.9653 17.2185Z'
				fill='#000088'
			/>
			<path
				d='M8.43571 11.7914L11.1327 12.6708L12.4786 12.8624L11.2161 12.363L8.43571 11.7914Z'
				fill='#000088'
			/>
			<path
				d='M9.92981 16.556C9.84781 16.6615 9.86741 16.8122 9.97359 16.8925C10.0798 16.9729 10.2323 16.9526 10.3143 16.8472C10.3963 16.7417 10.3767 16.5911 10.2705 16.5107C10.1643 16.4303 10.0118 16.4506 9.92981 16.556Z'
				fill='#000088'
			/>
			<path
				d='M8.90852 10.6678L11.2814 12.206L12.5317 12.7327L11.4445 11.9272L8.90852 10.6678Z'
				fill='#000088'
			/>
			<path
				d='M9.10284 15.6502C8.99607 15.7312 8.9755 15.8818 9.05691 15.9867C9.13831 16.0916 9.29085 16.1109 9.39762 16.0299C9.50439 15.9489 9.52495 15.7983 9.44355 15.6934C9.36215 15.5886 9.20961 15.5692 9.10284 15.6502Z'
				fill='#000088'
			/>
			<path
				d='M9.65953 9.70117L11.5481 11.794L12.6175 12.6226L11.7785 11.5664L9.65953 9.70117Z'
				fill='#000088'
			/>
			<path
				d='M8.54122 14.5632C8.417 14.6141 8.35776 14.7545 8.40889 14.8767C8.46002 14.999 8.60217 15.0568 8.72638 15.0058C8.8506 14.9549 8.90984 14.8145 8.85871 14.6922C8.80758 14.57 8.66543 14.5122 8.54122 14.5632Z'
				fill='#000088'
			/>
			<path
				d='M10.6381 8.95911L11.9138 11.4638L12.7293 12.5379L12.1956 11.3031L10.6381 8.95911Z'
				fill='#000088'
			/>
			<path
				d='M8.2837 13.3696C8.15042 13.3871 8.05639 13.5077 8.07368 13.6389C8.09097 13.7701 8.21303 13.8622 8.34632 13.8447C8.4796 13.8272 8.57363 13.7066 8.55634 13.5754C8.53905 13.4442 8.41698 13.3521 8.2837 13.3696Z'
				fill='#000088'
			/>
			<path
				d='M11.778 8.4927L12.3531 11.2386L12.8593 12.4846L12.6679 11.1552L11.778 8.4927Z'
				fill='#000088'
			/>
			<path
				d='M8.34772 12.1504C8.21499 12.1334 8.09292 12.2264 8.07508 12.3582C8.05724 12.4899 8.15037 12.6105 8.28311 12.6274C8.41584 12.6444 8.5379 12.5514 8.55574 12.4197C8.57359 12.288 8.48045 12.1674 8.34772 12.1504Z'
				fill='#000088'
			/>
			<path
				d='M13.0054 8.33327L12.8414 11.1331L12.9944 12.4658L13.1544 11.1323L13.0054 8.33327Z'
				fill='#000088'
			/>
			<path
				d='M8.72862 10.9901C8.60491 10.9396 8.46276 10.9982 8.41112 11.1209C8.35949 11.2437 8.41791 11.3841 8.54163 11.4345C8.66534 11.485 8.80748 11.4264 8.85912 11.3037C8.91076 11.1809 8.85233 11.0405 8.72862 10.9901Z'
				fill='#000088'
			/>
			<path
				d='M14.2242 8.49203L13.3335 11.1562L13.1384 12.4854L13.6438 11.2382L14.2242 8.49203Z'
				fill='#000088'
			/>
			<path
				d='M9.40071 9.9667C9.29454 9.8863 9.14199 9.90661 9.06 10.0121C8.978 10.1175 8.9976 10.2682 9.10377 10.3486C9.20995 10.429 9.36249 10.4087 9.44449 10.3032C9.52649 10.1978 9.50689 10.0471 9.40071 9.9667Z'
				fill='#000088'
			/>
			<path
				d='M15.3633 8.95805L13.8052 11.3042L13.269 12.5387L14.084 11.4631L15.3633 8.95805Z'
				fill='#000088'
			/>
			<path
				d='M10.3181 9.15017C10.2367 9.0453 10.0841 9.02593 9.97738 9.10691C9.87061 9.18788 9.85005 9.33854 9.93146 9.44341C10.0129 9.54829 10.1654 9.56766 10.2722 9.48668C10.3789 9.4057 10.3995 9.25504 10.3181 9.15017Z'
				fill='#000088'
			/>
			<path
				d='M16.3416 9.69936L14.2222 11.5676L13.3811 12.6233L14.45 11.7926L16.3416 9.69936Z'
				fill='#000088'
			/>
			<path
				d='M11.4173 8.59662C11.3656 8.47384 11.2234 8.41518 11.0998 8.46559C10.9761 8.516 10.9177 8.65639 10.9694 8.77916C11.0211 8.90194 11.1632 8.9606 11.2869 8.91019C11.4106 8.85978 11.4689 8.71939 11.4173 8.59662Z'
				fill='#000088'
			/>
			<path
				d='M17.0914 10.6677L14.5555 11.9271L13.4682 12.7326L14.7186 12.206L17.0914 10.6677Z'
				fill='#000088'
			/>
			<path
				d='M12.6253 8.34255C12.6074 8.21082 12.4854 8.1178 12.3526 8.13478C12.2199 8.15177 12.1267 8.27232 12.1446 8.40406C12.1624 8.53579 12.2845 8.62881 12.4172 8.61183C12.55 8.59484 12.6431 8.47429 12.6253 8.34255Z'
				fill='#000088'
			/>
			<path
				d='M17.5635 11.7936L14.7833 12.3607L13.522 12.8608L14.868 12.6723L17.5635 11.7936Z'
				fill='#000088'
			/>
			<path
				d='M13.86 8.40526C13.8772 8.27421 13.7829 8.15365 13.6495 8.13599C13.5161 8.11833 13.394 8.21026 13.3769 8.34131C13.3597 8.47237 13.454 8.59293 13.5874 8.61059C13.7208 8.62825 13.8429 8.53632 13.86 8.40526Z'
				fill='#000088'
			/>
			<path
				d='M17.7245 13.0061L14.8897 12.8439L13.5405 12.994L14.8907 13.1518L17.7245 13.0061Z'
				fill='#000088'
			/>
			<path
				d='M15.0347 8.78134C15.0858 8.65907 15.0266 8.51868 14.9024 8.46776C14.7783 8.41685 14.6361 8.4747 14.5849 8.59697C14.5338 8.71924 14.593 8.85963 14.7172 8.91054C14.8413 8.96146 14.9835 8.90361 15.0347 8.78134Z'
				fill='#000088'
			/>
			<path
				d='M17.5643 14.2089L14.867 13.3294L13.5212 13.1374L14.7838 13.6367L17.5643 14.2089Z'
				fill='#000088'
			/>
			<path
				d='M16.071 9.44488C16.1526 9.33987 16.1322 9.18921 16.0256 9.10837C15.919 9.02754 15.7664 9.04713 15.6849 9.15215C15.6033 9.25716 15.6237 9.40782 15.7303 9.48865C15.8369 9.56949 15.9895 9.54989 16.071 9.44488Z'
				fill='#000088'
			/>
			<path
				d='M17.0923 15.3337L14.7175 13.795L13.4674 13.2662L14.556 14.0712L17.0923 15.3337Z'
				fill='#000088'
			/>
			<path
				d='M16.8981 10.3507C17.0044 10.2702 17.0243 10.1195 16.9424 10.0142C16.8606 9.90893 16.708 9.88886 16.6017 9.9694C16.4954 10.0499 16.4755 10.2006 16.5574 10.3059C16.6392 10.4112 16.7918 10.4313 16.8981 10.3507Z'
				fill='#000088'
			/>
			<path
				d='M16.3415 16.2998L14.4506 14.2067L13.3816 13.3764L14.2221 14.4323L16.3415 16.2998Z'
				fill='#000088'
			/>
			<path
				d='M17.4593 11.4373C17.5832 11.3868 17.6418 11.2464 17.5903 11.1238C17.5388 11.0012 17.3967 10.9428 17.2728 10.9934C17.149 11.044 17.0903 11.1843 17.1418 11.3069C17.1933 11.4295 17.3355 11.4879 17.4593 11.4373Z'
				fill='#000088'
			/>
			<path
				d='M15.3628 17.0413L14.0847 14.5364L13.2695 13.4613L13.8049 14.6959L15.3628 17.0413Z'
				fill='#000088'
			/>
			<path
				d='M17.7169 12.6304C17.8498 12.6132 17.9432 12.4927 17.9255 12.3611C17.9079 12.2296 17.7858 12.1368 17.6529 12.154C17.52 12.1711 17.4265 12.2917 17.4442 12.4233C17.4619 12.5548 17.5839 12.6476 17.7169 12.6304Z'
				fill='#000088'
			/>
			<path
				d='M14.2235 17.5078L13.6448 14.7617L13.1392 13.5149L13.3331 14.8442L14.2235 17.5078Z'
				fill='#000088'
			/>
		</g>
		<defs>
			<clipPath id='clip0_5_20022'>
				<rect
					width='54'
					height='40'
					fill='white'
					transform='translate(-14 -7)'
				/>
			</clipPath>
		</defs>
	</svg>
);

const filstar = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
	>
		<rect width='24' height='24' fill='white' />
		<path
			d='M11.2827 3.45307C11.5131 2.98613 11.6284 2.75266 11.7848 2.67807C11.9209 2.61317 12.0791 2.61317 12.2152 2.67807C12.3717 2.75266 12.4869 2.98613 12.7174 3.45307L14.9041 7.88304C14.9721 8.02089 15.0061 8.08982 15.0558 8.14333C15.0999 8.19071 15.1527 8.2291 15.2113 8.25638C15.2776 8.28718 15.3536 8.2983 15.5057 8.32053L20.397 9.03546C20.9121 9.11075 21.1696 9.14839 21.2888 9.27419C21.3925 9.38365 21.4412 9.53405 21.4215 9.68353C21.3988 9.85533 21.2124 10.0369 20.8395 10.4001L17.3014 13.8462C17.1912 13.9536 17.136 14.0073 17.1004 14.0712C17.0689 14.1278 17.0487 14.19 17.0409 14.2543C17.0321 14.3269 17.0451 14.4028 17.0711 14.5545L17.906 19.4219C17.994 19.9352 18.038 20.1919 17.9553 20.3443C17.8833 20.4768 17.7554 20.5697 17.6071 20.5972C17.4366 20.6288 17.2061 20.5076 16.7451 20.2652L12.3724 17.9656C12.2361 17.8939 12.168 17.8581 12.0962 17.844C12.0327 17.8316 11.9673 17.8316 11.9038 17.844C11.832 17.8581 11.7639 17.8939 11.6277 17.9656L7.25492 20.2652C6.79392 20.5076 6.56341 20.6288 6.39297 20.5972C6.24468 20.5697 6.11672 20.4768 6.04474 20.3443C5.962 20.1919 6.00603 19.9352 6.09407 19.4219L6.92889 14.5545C6.95491 14.4028 6.96793 14.3269 6.95912 14.2543C6.95132 14.19 6.93111 14.1278 6.89961 14.0712C6.86402 14.0073 6.80888 13.9536 6.69859 13.8462L3.16056 10.4001C2.78766 10.0369 2.60121 9.85533 2.57853 9.68353C2.55879 9.53405 2.60755 9.38365 2.71125 9.27419C2.83044 9.14839 3.08797 9.11075 3.60304 9.03546L8.49431 8.32053C8.64642 8.2983 8.72248 8.28718 8.78872 8.25638C8.84736 8.2291 8.90016 8.19071 8.94419 8.14333C8.99391 8.08982 9.02793 8.02089 9.09597 7.88304L11.2827 3.45307Z'
			fill='#FBBC38'
		/>
	</svg>
);

const blankstar = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
	>
		<path
			d='M11.2827 3.45307C11.5131 2.98613 11.6284 2.75266 11.7848 2.67807C11.9209 2.61317 12.0791 2.61317 12.2152 2.67807C12.3717 2.75266 12.4869 2.98613 12.7174 3.45307L14.9041 7.88304C14.9721 8.02089 15.0061 8.08982 15.0558 8.14333C15.0999 8.19071 15.1527 8.22911 15.2113 8.25638C15.2776 8.28718 15.3536 8.2983 15.5057 8.32053L20.397 9.03546C20.9121 9.11075 21.1696 9.14839 21.2888 9.27419C21.3925 9.38365 21.4412 9.53405 21.4215 9.68353C21.3988 9.85533 21.2124 10.0369 20.8395 10.4001L17.3014 13.8462C17.1912 13.9536 17.136 14.0073 17.1004 14.0712C17.0689 14.1278 17.0487 14.19 17.0409 14.2543C17.0321 14.3269 17.0451 14.4027 17.0711 14.5545L17.906 19.4219C17.994 19.9352 18.038 20.1919 17.9553 20.3443C17.8833 20.4768 17.7554 20.5697 17.6071 20.5972C17.4366 20.6288 17.2061 20.5076 16.7451 20.2652L12.3724 17.9656C12.2361 17.8939 12.168 17.8581 12.0962 17.844C12.0327 17.8316 11.9673 17.8316 11.9038 17.844C11.832 17.8581 11.7639 17.8939 11.6277 17.9656L7.25492 20.2652C6.79392 20.5076 6.56341 20.6288 6.39297 20.5972C6.24468 20.5697 6.11672 20.4768 6.04474 20.3443C5.962 20.1919 6.00603 19.9352 6.09407 19.4219L6.92889 14.5545C6.95491 14.4027 6.96793 14.3269 6.95912 14.2543C6.95132 14.19 6.93111 14.1278 6.89961 14.0712C6.86402 14.0073 6.80888 13.9536 6.69859 13.8462L3.16056 10.4001C2.78766 10.0369 2.60121 9.85533 2.57853 9.68353C2.55879 9.53405 2.60755 9.38365 2.71125 9.27419C2.83044 9.14839 3.08797 9.11075 3.60304 9.03546L8.49431 8.32053C8.64642 8.2983 8.72248 8.28718 8.78872 8.25638C8.84736 8.22911 8.90016 8.19071 8.94419 8.14333C8.99391 8.08982 9.02793 8.02089 9.09597 7.88304L11.2827 3.45307Z'
			stroke='#CCCFD2'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
