// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kycStatusPage_kycStatusPage__iPUvf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
}

.kycStatusPage_kycStatusPage__iPUvf .kycStatusPage_statusImage__vyMFG svg {
  width: 5.44813rem;
  height: 7.5rem;
}

.kycStatusPage_statusPageContent__ypId1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.kycStatusPage_statusText__CXAgO {
  width: 13.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.875rem;
  color: var(--text-secondary, #475467);
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
}

.kycStatusPage_label__6Lo35 {
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/KYC/kycStatusPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,qCAAqC;EACrC,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB,EAAE,aAAa;AACtC;;AAEA;AACA","sourcesContent":[".kycStatusPage {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 2rem;\n  align-self: stretch;\n}\n\n.kycStatusPage .statusImage svg {\n  width: 5.44813rem;\n  height: 7.5rem;\n}\n\n.statusPageContent {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 1rem;\n}\n\n.statusText {\n  width: 13.625rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 0.875rem;\n  color: var(--text-secondary, #475467);\n  text-align: center;\n  font-family: Poppins;\n  font-size: 0.875rem;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1.375rem; /* 157.143% */\n}\n\n.label {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"kycStatusPage": `kycStatusPage_kycStatusPage__iPUvf`,
	"statusImage": `kycStatusPage_statusImage__vyMFG`,
	"statusPageContent": `kycStatusPage_statusPageContent__ypId1`,
	"statusText": `kycStatusPage_statusText__CXAgO`,
	"label": `kycStatusPage_label__6Lo35`
};
export default ___CSS_LOADER_EXPORT___;
