// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/futura);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/Karla);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/futura-md-bt);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://db.onlinewebfonts.com/c/e55e9079ee863276569c8a68d776ef04?family=Futura+Md+Medium+BT);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/be-vietnam);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/poppins);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
}

a {
	text-decoration: none;
}
p {
	margin: 0;
}

button,
a {
	cursor: pointer;
}

.flex_center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.body-no-scroll {
	overflow: hidden;
}

.container_max {
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding-inline: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAQA;;;CAGC,sBAAsB;CACtB,SAAS;CACT,UAAU;CACV,gCAAgC;AACjC;;AAEA;CACC,qBAAqB;AACtB;AACA;CACC,SAAS;AACV;;AAEA;;CAEC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,WAAW;CACX,iBAAiB;CACjB,cAAc;CACd,oBAAoB;AACrB","sourcesContent":["@import url(\"https://fonts.cdnfonts.com/css/futura\");\n@import url(\"https://fonts.cdnfonts.com/css/Karla\");\n@import url(\"https://fonts.cdnfonts.com/css/futura-md-bt\");\n@import url(\"https://db.onlinewebfonts.com/c/e55e9079ee863276569c8a68d776ef04?family=Futura+Md+Medium+BT\");\n@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap\");\n@import url(\"https://fonts.cdnfonts.com/css/be-vietnam\");\n@import url(\"https://fonts.cdnfonts.com/css/poppins\");\n\n*,\n*::after,\n*::before {\n\tbox-sizing: border-box;\n\tmargin: 0;\n\tpadding: 0;\n\tfont-family: \"Inter\", sans-serif;\n}\n\na {\n\ttext-decoration: none;\n}\np {\n\tmargin: 0;\n}\n\nbutton,\na {\n\tcursor: pointer;\n}\n\n.flex_center {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.body-no-scroll {\n\toverflow: hidden;\n}\n\n.container_max {\n\twidth: 100%;\n\tmax-width: 1280px;\n\tmargin: 0 auto;\n\tpadding-inline: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
