// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableContainer_Header__z2E2A {
	padding: 0.75rem 1.5rem;
	gap: 12px;
	opacity: 0px;
	background: #f9fafb;
	border-bottom: 1px solid #eaecf0;
}
.tableContainer_Badge__R-g3f {
	display: flex;
	padding: 0.25rem 0.5rem;
	align-items: center;
	border-radius: 1rem;
	mix-blend-mode: multiply;
	height: -moz-fit-content;
	height: fit-content;
	line-height: normal;
}
.tableContainer_StatusColumn__h3Da4 {
	display: flex;
	align-items: center;
}
@media only screen and (max-width: 500px) {
	.tableContainer_TransactionTableContainer__NSkCR {
		width: 100%;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/myTransaction/table/tableContainer.module.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,SAAS;CACT,YAAY;CACZ,mBAAmB;CACnB,gCAAgC;AACjC;AACA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,mBAAmB;CACnB,wBAAwB;CACxB,wBAAmB;CAAnB,mBAAmB;CACnB,mBAAmB;AACpB;AACA;CACC,aAAa;CACb,mBAAmB;AACpB;AACA;CACC;EACC,WAAW;CACZ;AACD","sourcesContent":[".Header {\n\tpadding: 0.75rem 1.5rem;\n\tgap: 12px;\n\topacity: 0px;\n\tbackground: #f9fafb;\n\tborder-bottom: 1px solid #eaecf0;\n}\n.Badge {\n\tdisplay: flex;\n\tpadding: 0.25rem 0.5rem;\n\talign-items: center;\n\tborder-radius: 1rem;\n\tmix-blend-mode: multiply;\n\theight: fit-content;\n\tline-height: normal;\n}\n.StatusColumn {\n\tdisplay: flex;\n\talign-items: center;\n}\n@media only screen and (max-width: 500px) {\n\t.TransactionTableContainer {\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": `tableContainer_Header__z2E2A`,
	"Badge": `tableContainer_Badge__R-g3f`,
	"StatusColumn": `tableContainer_StatusColumn__h3Da4`,
	"TransactionTableContainer": `tableContainer_TransactionTableContainer__NSkCR`
};
export default ___CSS_LOADER_EXPORT___;
