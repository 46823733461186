// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardMainTopBottom_dashboardMainTopBottom__8DvT1 {
	width: 100%;
	padding: 2rem 0rem 3rem 0rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	position: relative;
}

.dashboardMainTopBottom_opacity__hEf2p {
	display: none;
}

@media only screen and (max-width: 1024px) {
	.dashboardMainTopBottom_dashboardMainTopBottom__8DvT1 {
		width: 100%;
	}

	.dashboardMainTopBottom_opacity__hEf2p {
		display: block;
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		height: 100vh;
		background: rgba(52, 64, 84, 0.6);

		/* Background blur/md */
		-webkit-backdrop-filter: blur(8px);
		        backdrop-filter: blur(8px);
		z-index: 2;
	}
}
`, "",{"version":3,"sources":["webpack://./src/layout/dashboardMainTopBottom.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,4BAA4B;CAC5B,aAAa;CACb,sBAAsB;CACtB,SAAS;CACT,kBAAkB;AACnB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC;EACC,WAAW;CACZ;;CAEA;EACC,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,OAAO;EACP,aAAa;EACb,iCAAiC;;EAEjC,uBAAuB;EACvB,kCAA0B;UAA1B,0BAA0B;EAC1B,UAAU;CACX;AACD","sourcesContent":[".dashboardMainTopBottom {\n\twidth: 100%;\n\tpadding: 2rem 0rem 3rem 0rem;\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 2rem;\n\tposition: relative;\n}\n\n.opacity {\n\tdisplay: none;\n}\n\n@media only screen and (max-width: 1024px) {\n\t.dashboardMainTopBottom {\n\t\twidth: 100%;\n\t}\n\n\t.opacity {\n\t\tdisplay: block;\n\t\tposition: absolute;\n\t\twidth: 100%;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\theight: 100vh;\n\t\tbackground: rgba(52, 64, 84, 0.6);\n\n\t\t/* Background blur/md */\n\t\tbackdrop-filter: blur(8px);\n\t\tz-index: 2;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardMainTopBottom": `dashboardMainTopBottom_dashboardMainTopBottom__8DvT1`,
	"opacity": `dashboardMainTopBottom_opacity__hEf2p`
};
export default ___CSS_LOADER_EXPORT___;
