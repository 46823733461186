// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deals_deals_container__C9MpV {
	display: flex;
	padding-block: 4rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.625rem;
	border-bottom: 0.0625rem solid #eaeaea;
	margin-bottom: 4rem;
}
.deals_deals_container__C9MpV .deals_content__qEgAj {
	display: flex;
	flex-direction: column;
	gap: 4.5rem;
	width: 100%;
}
.deals_content__qEgAj .deals_head_container__RdYbn {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.875rem;
}
.deals_head_container__RdYbn .deals_head__kjAf\\+ {
	color: #070a1e;
	text-align: center;
	font-family: Futura;
	font-size: 2.625rem;
	font-style: normal;
	font-weight: 700;
	line-height: 136%; /* 3.57rem */
}
.deals_head_container__RdYbn .deals_subhead__wm0O\\+ {
	color: #5c6077;
	text-align: center;
	font-family: Inter;
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2.25rem; /* 163.636% */
	letter-spacing: -0.0275rem;
}
.deals_swiper__loXgq {
	padding-inline: 16px;
}
.deals_swiper__loXgq > div {
	align-items: stretch;
	padding-block: 1rem;
}
.deals_swiper__loXgq > div > div {
	height: auto;
}

@media only screen and (max-width: 768px) {
	.deals_title_container__AI613 .deals_title__yUEPO {
		max-width: none;
	}
	.deals_head_container__RdYbn .deals_head__kjAf\\+ {
		font-size: 1.375rem;
	}
	.deals_head_container__RdYbn .deals_subhead__wm0O\\+ {
		font-size: 1rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/home/deals/deals.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,sBAAsB;CACtB,uBAAuB;CACvB,aAAa;CACb,sCAAsC;CACtC,mBAAmB;AACpB;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,WAAW;CACX,WAAW;AACZ;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,aAAa;AACd;AACA;CACC,cAAc;CACd,kBAAkB;CAClB,mBAAmB;CACnB,mBAAmB;CACnB,kBAAkB;CAClB,gBAAgB;CAChB,iBAAiB,EAAE,YAAY;AAChC;AACA;CACC,cAAc;CACd,kBAAkB;CAClB,kBAAkB;CAClB,mBAAmB;CACnB,kBAAkB;CAClB,gBAAgB;CAChB,oBAAoB,EAAE,aAAa;CACnC,0BAA0B;AAC3B;AACA;CACC,oBAAoB;AACrB;AACA;CACC,oBAAoB;CACpB,mBAAmB;AACpB;AACA;CACC,YAAY;AACb;;AAEA;CACC;EACC,eAAe;CAChB;CACA;EACC,mBAAmB;CACpB;CACA;EACC,eAAe;CAChB;AACD","sourcesContent":[".deals_container {\n\tdisplay: flex;\n\tpadding-block: 4rem;\n\tflex-direction: column;\n\talign-items: flex-start;\n\tgap: 0.625rem;\n\tborder-bottom: 0.0625rem solid #eaeaea;\n\tmargin-bottom: 4rem;\n}\n.deals_container .content {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 4.5rem;\n\twidth: 100%;\n}\n.content .head_container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tgap: 0.875rem;\n}\n.head_container .head {\n\tcolor: #070a1e;\n\ttext-align: center;\n\tfont-family: Futura;\n\tfont-size: 2.625rem;\n\tfont-style: normal;\n\tfont-weight: 700;\n\tline-height: 136%; /* 3.57rem */\n}\n.head_container .subhead {\n\tcolor: #5c6077;\n\ttext-align: center;\n\tfont-family: Inter;\n\tfont-size: 1.375rem;\n\tfont-style: normal;\n\tfont-weight: 400;\n\tline-height: 2.25rem; /* 163.636% */\n\tletter-spacing: -0.0275rem;\n}\n.swiper {\n\tpadding-inline: 16px;\n}\n.swiper > div {\n\talign-items: stretch;\n\tpadding-block: 1rem;\n}\n.swiper > div > div {\n\theight: auto;\n}\n\n@media only screen and (max-width: 768px) {\n\t.title_container .title {\n\t\tmax-width: none;\n\t}\n\t.head_container .head {\n\t\tfont-size: 1.375rem;\n\t}\n\t.head_container .subhead {\n\t\tfont-size: 1rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deals_container": `deals_deals_container__C9MpV`,
	"content": `deals_content__qEgAj`,
	"head_container": `deals_head_container__RdYbn`,
	"head": `deals_head__kjAf+`,
	"subhead": `deals_subhead__wm0O+`,
	"swiper": `deals_swiper__loXgq`,
	"title_container": `deals_title_container__AI613`,
	"title": `deals_title__yUEPO`
};
export default ___CSS_LOADER_EXPORT___;
