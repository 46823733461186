// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TabContent */
.myTransactionMain_TabContent__E4kgx {
	align-self: stretch;
	border-bottom: 0.0625rem #eaecf0 solid;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.5rem;
}

/* TabFilters */
.myTransactionMain_TabFilters__vT4YV {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 1rem;
}

/* TabButton */
.myTransactionMain_TabButton__wuMa3 {
	padding: 0.25rem 0.0625rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}
@media only screen and (max-width: 768px) {
	.myTransactionMain_TabContent__E4kgx {
		width: 100%;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/myTransaction/myTransactionMain.module.css"],"names":[],"mappings":"AAAA,eAAe;AACf;CACC,mBAAmB;CACnB,sCAAsC;CACtC,aAAa;CACb,sBAAsB;CACtB,2BAA2B;CAC3B,uBAAuB;CACvB,WAAW;AACZ;;AAEA,eAAe;AACf;CACC,aAAa;CACb,2BAA2B;CAC3B,uBAAuB;CACvB,SAAS;AACV;;AAEA,cAAc;AACd;CACC,0BAA0B;CAC1B,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,WAAW;AACZ;AACA;CACC;EACC,WAAW;CACZ;AACD","sourcesContent":["/* TabContent */\n.TabContent {\n\talign-self: stretch;\n\tborder-bottom: 0.0625rem #eaecf0 solid;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: flex-start;\n\talign-items: flex-start;\n\tgap: 0.5rem;\n}\n\n/* TabFilters */\n.TabFilters {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-items: flex-start;\n\tgap: 1rem;\n}\n\n/* TabButton */\n.TabButton {\n\tpadding: 0.25rem 0.0625rem;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tgap: 0.5rem;\n}\n@media only screen and (max-width: 768px) {\n\t.TabContent {\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TabContent": `myTransactionMain_TabContent__E4kgx`,
	"TabFilters": `myTransactionMain_TabFilters__vT4YV`,
	"TabButton": `myTransactionMain_TabButton__wuMa3`
};
export default ___CSS_LOADER_EXPORT___;
