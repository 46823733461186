import React from "react";
import styles from "./footer.module.css"; // Replace with your actual CSS module path
import { LogoLight } from "../logo/logo";

const Footer = () => {
	return (
		<div className={styles.footer_container}>
			<div className='container_max'>
				<div className={styles.menu_container}>
					<div className={styles.menu_content}>
						<a href='/' className={styles.logo}>
							<LogoLight />
						</a>

						<div className={styles.menu_items}>
							<a href='/#about-us' className={styles.item}>
								About us
							</a>

							<a href='/#faq' className={styles.item}>
								FAQs
							</a>
						</div>
					</div>

					<div className={styles.footer_divider}></div>
				</div>

				<div className={styles.social_container}>
					<div className={styles.copyright}>
						<div className={styles.text}>
							© {new Date().getFullYear()} Rakeback. All rights reserved.
						</div>
					</div>

					<div className={styles.social_links}>
						<div className={styles.link_icon}>
							<div className={styles.icon}>{discord}</div>
						</div>
						<a
							className={styles.link_icon}
							href='https://x.com/rakebackcom'
							target='_blank'
						>
							<div className={styles.icon}>{twitter}</div>
						</a>
						<div className={styles.link_icon}>
							<div className={styles.icon}>{ig}</div>
						</div>
						<div className={styles.link_icon}>
							<div className={styles.icon}>{telegram}</div>
						</div>
						<a
							href='https://www.facebook.com/RakebackCom'
							className={styles.link_icon}
							target='_blank'
						>
							<div className={styles.icon}>{facebook}</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;

const discord = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
	>
		<g clipPath='url(#clip0_1_8515)'>
			<path
				d='M7.44665 7.62028C7.20782 7.63992 6.98512 7.74868 6.82276 7.92494C6.66041 8.10119 6.57028 8.33207 6.57028 8.5717C6.57028 8.81134 6.66041 9.04221 6.82276 9.21847C6.98512 9.39473 7.20782 9.50348 7.44665 9.52313C7.56684 9.51873 7.68497 9.49056 7.79421 9.44026C7.90345 9.38996 8.00165 9.31852 8.08314 9.23006C8.16462 9.1416 8.22778 9.03788 8.26896 8.92488C8.31014 8.81188 8.32854 8.69185 8.32308 8.5717C8.32897 8.45146 8.31088 8.33124 8.26986 8.21805C8.22884 8.10487 8.1657 8.00098 8.08414 7.91243C8.00257 7.82388 7.9042 7.75245 7.79475 7.70229C7.68531 7.65213 7.56698 7.62425 7.44665 7.62028ZM10.5752 7.62028C10.3837 7.60453 10.1919 7.64694 10.0249 7.74196C9.85791 7.83698 9.72347 7.98019 9.63916 8.15285C9.55486 8.32552 9.52462 8.5196 9.5524 8.70973C9.58018 8.89986 9.66468 9.07718 9.79487 9.2185C9.92505 9.35983 10.0949 9.45858 10.2821 9.50184C10.4693 9.5451 10.6652 9.53086 10.8442 9.46099C11.0232 9.39111 11.1769 9.26885 11.2853 9.11019C11.3937 8.95153 11.4517 8.76385 11.4517 8.5717C11.4571 8.45173 11.4388 8.33187 11.3977 8.21901C11.3567 8.10614 11.2937 8.00252 11.2125 7.91409C11.1312 7.82566 11.0333 7.75417 10.9243 7.70375C10.8153 7.65332 10.6974 7.62496 10.5774 7.62028H10.5752Z'
				fill='#3968EB'
			/>
			<path
				d='M14.7428 0.428589H3.25712C3.02563 0.429151 2.79651 0.475339 2.58288 0.564513C2.36925 0.653687 2.17529 0.784097 2.0121 0.948286C1.84891 1.11247 1.71968 1.30722 1.6318 1.52139C1.54393 1.73556 1.49914 1.96495 1.49998 2.19645V13.7829C1.49914 14.0144 1.54393 14.2438 1.6318 14.4579C1.71968 14.6721 1.84891 14.8668 2.0121 15.031C2.17529 15.1952 2.36925 15.3256 2.58288 15.4148C2.79651 15.504 3.02563 15.5502 3.25712 15.5507H12.9771L12.5228 13.965L13.62 14.9829L14.6571 15.9429L16.5 17.5714V2.19645C16.5008 1.96495 16.456 1.73556 16.3682 1.52139C16.2803 1.30722 16.1511 1.11247 15.9879 0.948286C15.8247 0.784097 15.6307 0.653687 15.4171 0.564513C15.2034 0.475339 14.9743 0.429151 14.7428 0.428589V0.428589ZM11.4343 11.625C11.4343 11.625 11.1257 11.2564 10.8686 10.9286C11.4903 10.7833 12.0406 10.4228 12.4221 9.91073C12.1137 10.116 11.783 10.2856 11.4364 10.4164C11.0377 10.5867 10.6206 10.7103 10.1936 10.785C9.45945 10.9202 8.70659 10.9173 7.97355 10.7764C7.5432 10.6922 7.12144 10.5689 6.71355 10.4079C6.36912 10.2743 6.04008 10.1041 5.73212 9.90002C6.09893 10.4017 6.62951 10.7595 7.23212 10.9114C6.97498 11.2372 6.65784 11.6229 6.65784 11.6229C6.14867 11.6366 5.64393 11.525 5.18805 11.2978C4.73218 11.0706 4.33914 10.7348 4.04355 10.32C4.07071 8.58251 4.49201 6.87384 5.2757 5.32287C5.96659 4.78031 6.80899 4.46581 7.68641 4.42287L7.77212 4.52573C6.94733 4.72985 6.17764 5.11323 5.51784 5.64859C5.51784 5.64859 5.70641 5.54573 6.02355 5.40002C6.64035 5.11855 7.29774 4.93631 7.97141 4.86002C8.01939 4.85009 8.06815 4.84435 8.11712 4.84287C8.69151 4.76804 9.2728 4.76228 9.84855 4.82573C10.754 4.92915 11.6304 5.20868 12.4286 5.64859C11.8022 5.13873 11.0747 4.76769 10.2943 4.56002L10.4143 4.42287C11.2917 4.46581 12.1341 4.78031 12.825 5.32287C13.6087 6.87384 14.03 8.58251 14.0571 10.32C13.7593 10.7348 13.3647 11.0705 12.9076 11.2979C12.4505 11.5254 11.9447 11.6377 11.4343 11.625Z'
				fill='#3968EB'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_8515'>
				<rect
					width='17.1429'
					height='17.1429'
					fill='white'
					transform='translate(0.428558 0.428589)'
				/>
			</clipPath>
		</defs>
	</svg>
);
const twitter = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
	>
		<g clipPath='url(#clip0_1_8520)'>
			<path
				d='M17.5713 3.69672C16.9284 3.96458 16.2856 4.17887 15.5356 4.23244C16.2856 3.80387 16.8213 3.10744 17.0891 2.30387C16.3927 2.73244 15.6427 3.0003 14.8391 3.16101C14.1963 2.46458 13.2856 2.03601 12.3213 2.03601C10.3927 2.03601 8.78556 3.58958 8.78556 5.57173C8.78556 5.83958 8.83913 6.10744 8.89271 6.3753C5.94628 6.21458 3.37485 4.82172 1.60699 2.67887C1.28556 3.21458 1.12485 3.80387 1.12485 4.44672C1.12485 5.67887 1.7677 6.7503 2.67842 7.39315C2.08913 7.39315 1.55342 7.23244 1.07128 6.96458V7.01815C1.07128 8.73244 2.30342 10.1253 3.91056 10.4467C3.58913 10.5003 3.32128 10.5539 2.99985 10.5539C2.78556 10.5539 2.57128 10.5539 2.35699 10.5003C2.78556 11.8932 4.12485 12.911 5.62485 12.9646C4.44628 13.9289 2.8927 14.4646 1.23199 14.4646C0.964133 14.4646 0.642705 14.4646 0.374847 14.411C1.98199 15.3753 3.85699 15.9646 5.83913 15.9646C12.3213 15.9646 15.857 10.6074 15.857 5.94673C15.857 5.78601 15.857 5.6253 15.857 5.51815C16.4998 4.98244 17.0891 4.39315 17.5713 3.69672Z'
				fill='#3968EB'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_8520'>
				<rect
					width='17.1429'
					height='17.1429'
					fill='white'
					transform='translate(0.428558 0.428589)'
				/>
			</clipPath>
		</defs>
	</svg>
);
const ig = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
	>
		<path
			d='M9.00024 11.7145C10.4993 11.7145 11.7145 10.4992 11.7145 9.00017C11.7145 7.50112 10.4993 6.28589 9.00024 6.28589C7.50118 6.28589 6.28595 7.50112 6.28595 9.00017C6.28595 10.4992 7.50118 11.7145 9.00024 11.7145Z'
			fill='#3968EB'
		/>
		<path
			d='M12.3923 0.8573H5.60662C2.98801 0.8573 0.8573 2.98869 0.8573 5.60798V12.3937C0.8573 15.0123 2.98869 17.143 5.60798 17.143H12.3937C15.0123 17.143 17.143 15.0116 17.143 12.3923V5.60662C17.143 2.98801 15.0116 0.8573 12.3923 0.8573ZM9.00016 13.0716C6.75476 13.0716 4.92873 11.2455 4.92873 9.00016C4.92873 6.75476 6.75476 4.92873 9.00016 4.92873C11.2455 4.92873 13.0716 6.75476 13.0716 9.00016C13.0716 11.2455 11.2455 13.0716 9.00016 13.0716ZM13.7502 4.92873C13.3756 4.92873 13.0716 4.62473 13.0716 4.25016C13.0716 3.87559 13.3756 3.57159 13.7502 3.57159C14.1247 3.57159 14.4287 3.87559 14.4287 4.25016C14.4287 4.62473 14.1247 4.92873 13.7502 4.92873Z'
			fill='#3968EB'
		/>
	</svg>
);
const telegram = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
	>
		<g clipPath='url(#clip0_1_8534)'>
			<path
				d='M17.5366 2.23341C17.5228 2.17014 17.4925 2.11167 17.4488 2.06395C17.405 2.01623 17.3493 1.98098 17.2875 1.9618C17.0624 1.9174 16.8295 1.93405 16.613 2.01001C16.613 2.01001 1.59322 7.40841 0.735541 8.00626C0.550184 8.13484 0.488577 8.2093 0.458041 8.29769C0.309648 8.72305 0.771434 8.91055 0.771434 8.91055L4.64251 10.1722C4.70789 10.1835 4.77501 10.1794 4.83858 10.1604C5.71929 9.6043 13.6988 4.56537 14.16 4.39608C14.2323 4.37412 14.2859 4.39608 14.2741 4.44966C14.0855 5.09573 7.19465 11.2195 7.15661 11.257C7.13812 11.2721 7.12359 11.2916 7.11426 11.3136C7.10493 11.3356 7.10108 11.3596 7.10304 11.3834L6.74304 15.1607C6.74304 15.1607 6.59197 16.3393 7.7684 15.1607C8.60251 14.3255 9.40286 13.6345 9.80411 13.2986C11.1354 14.2173 12.5679 15.2336 13.1855 15.7629C13.2892 15.8635 13.412 15.9423 13.5468 15.9944C13.6816 16.0465 13.8254 16.0709 13.9698 16.0661C14.5591 16.0436 14.7198 15.3991 14.7198 15.3991C14.7198 15.3991 17.4563 4.38751 17.5484 2.91162C17.557 2.76698 17.5693 2.6743 17.5704 2.57519C17.5752 2.46024 17.5639 2.34518 17.5366 2.23341V2.23341Z'
				fill='#3968EB'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_8534'>
				<rect
					width='17.1429'
					height='17.1429'
					fill='white'
					transform='translate(0.428589 0.428589)'
				/>
			</clipPath>
		</defs>
	</svg>
);
const facebook = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
	>
		<g clipPath='url(#clip0_1_8538)'>
			<path
				d='M7.10518 17.0361V10.0719H4.71472V6.85758H7.10518V4.61292C7.10518 2.16781 8.65311 0.964722 10.8343 0.964722C11.8791 0.964722 12.7771 1.04251 13.0388 1.07728V3.63257L11.526 3.63325C10.3398 3.63326 10.0719 4.19695 10.0719 5.02413V6.85758H13.2861L12.2147 10.0719H10.0719V17.0361H7.10518Z'
				fill='#3968EB'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1_8538'>
				<rect
					width='17.1429'
					height='17.1429'
					fill='white'
					transform='translate(0.428589 0.428589)'
				/>
			</clipPath>
		</defs>
	</svg>
);
