// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offerAndDeal_offer_and_deals_wrapper__jWPGr {
	display: flex;
	flex-direction: column;
	gap: 4rem;
	position: relative;
}

.offerAndDeal_offer_and_deals_wrapper_main__BZ5aY {
	width: 100%;
	position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/offerAndDeal/offerAndDeal.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,SAAS;CACT,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,kBAAkB;AACnB","sourcesContent":[".offer_and_deals_wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 4rem;\n\tposition: relative;\n}\n\n.offer_and_deals_wrapper_main {\n\twidth: 100%;\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offer_and_deals_wrapper": `offerAndDeal_offer_and_deals_wrapper__jWPGr`,
	"offer_and_deals_wrapper_main": `offerAndDeal_offer_and_deals_wrapper_main__BZ5aY`
};
export default ___CSS_LOADER_EXPORT___;
