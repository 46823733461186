// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardContainer_offer_cards_container__M4cSe {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 1.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/offerAndDeal/cards/cardContainer.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,4DAA4D;CAC5D,YAAY;AACb","sourcesContent":[".offer_cards_container {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n\tgap: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offer_cards_container": `cardContainer_offer_cards_container__M4cSe`
};
export default ___CSS_LOADER_EXPORT___;
