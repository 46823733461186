// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardDealCards_DashboardDealCardsContainer__9Y53b {
	border-radius: 0.75rem;
	border: 1px solid #eaecf0;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
		0px 1px 3px 0px rgba(16, 24, 40, 0.1);
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	padding: 1.5rem;
	width: 100%;
}
.dashboardDealCards_cardsContent__NYYGL {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 1rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
}
.dashboardDealCards_deal_head__AOtL7 {
	display: flex;
	align-items: center;
	gap: 1.25rem;
	align-self: stretch;
	color: var(--text-primary, #101828);

	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.75rem; /* 155.556% */
}
@media only screen and (max-width: 1024px) {
	.dashboardDealCards_DashboardDealCardsContainer__9Y53b {
		padding: 1.5rem 1rem;
	}
	.dashboardDealCards_cardsContent__NYYGL {
		grid-template-columns: 1fr;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/home/dashboardDealCards.module.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,yBAAyB;CACzB;uCACsC;CACtC,aAAa;CACb,sBAAsB;CACtB,YAAY;CACZ,eAAe;CACf,WAAW;AACZ;AACA;CACC,WAAW;CACX,aAAa;CACb,mBAAmB;CACnB,mBAAmB;CACnB,SAAS;CACT,aAAa;CACb,4DAA4D;AAC7D;AACA;CACC,aAAa;CACb,mBAAmB;CACnB,YAAY;CACZ,mBAAmB;CACnB,mCAAmC;;CAEnC,mBAAmB;CACnB,gBAAgB;CAChB,oBAAoB,EAAE,aAAa;AACpC;AACA;CACC;EACC,oBAAoB;CACrB;CACA;EACC,0BAA0B;CAC3B;AACD","sourcesContent":[".DashboardDealCardsContainer {\n\tborder-radius: 0.75rem;\n\tborder: 1px solid #eaecf0;\n\tbox-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),\n\t\t0px 1px 3px 0px rgba(16, 24, 40, 0.1);\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 1.25rem;\n\tpadding: 1.5rem;\n\twidth: 100%;\n}\n.cardsContent {\n\twidth: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\tflex-direction: row;\n\tgap: 1rem;\n\tdisplay: grid;\n\tgrid-template-columns: repeat(auto-fill, minmax(360px, 1fr));\n}\n.deal_head {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 1.25rem;\n\talign-self: stretch;\n\tcolor: var(--text-primary, #101828);\n\n\tfont-size: 1.125rem;\n\tfont-weight: 600;\n\tline-height: 1.75rem; /* 155.556% */\n}\n@media only screen and (max-width: 1024px) {\n\t.DashboardDealCardsContainer {\n\t\tpadding: 1.5rem 1rem;\n\t}\n\t.cardsContent {\n\t\tgrid-template-columns: 1fr;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DashboardDealCardsContainer": `dashboardDealCards_DashboardDealCardsContainer__9Y53b`,
	"cardsContent": `dashboardDealCards_cardsContent__NYYGL`,
	"deal_head": `dashboardDealCards_deal_head__AOtL7`
};
export default ___CSS_LOADER_EXPORT___;
