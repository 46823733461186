// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbtn_navbtn_container__YbmiG:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.navbtn_navbtn_container__YbmiG .navbtn_text__qOcVH {
}

.navbtn_navbtn_container__YbmiG .navbtn_icon__G7nWS {
	height: -moz-fit-content;
	height: fit-content;
}

.navbtn_icon__G7nWS svg {
	/* width: 100%;
  height: 100%; */
}

@media only screen and (max-width: 768px) {
	.navbtn_navbtn_container__YbmiG {
		padding: 0.5rem 1rem;

		/* padding: 8px 20px 8px 20px; */
		gap: 8px;
	}
	.navbtn_navbtn_container__YbmiG .navbtn_text__qOcVH {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/common/button/navbtn/navbtn.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,mBAAmB;AACpB;;AAEA;AACA;;AAEA;CACC,wBAAmB;CAAnB,mBAAmB;AACpB;;AAEA;CACC;iBACgB;AACjB;;AAEA;CACC;EACC,oBAAoB;;EAEpB,gCAAgC;EAChC,QAAQ;CACT;CACA;EACC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;CAClB;AACD","sourcesContent":[".navbtn_container:disabled {\n\topacity: 0.5;\n\tcursor: not-allowed;\n}\n\n.navbtn_container .text {\n}\n\n.navbtn_container .icon {\n\theight: fit-content;\n}\n\n.icon svg {\n\t/* width: 100%;\n  height: 100%; */\n}\n\n@media only screen and (max-width: 768px) {\n\t.navbtn_container {\n\t\tpadding: 0.5rem 1rem;\n\n\t\t/* padding: 8px 20px 8px 20px; */\n\t\tgap: 8px;\n\t}\n\t.navbtn_container .text {\n\t\tfont-size: 14px;\n\t\tfont-weight: 500;\n\t\tline-height: 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbtn_container": `navbtn_navbtn_container__YbmiG`,
	"text": `navbtn_text__qOcVH`,
	"icon": `navbtn_icon__G7nWS`
};
export default ___CSS_LOADER_EXPORT___;
