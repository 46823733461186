// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo_logo__XIK9M {
	width: 9.125rem;
	height: 2.8125rem;
	flex-shrink: 0;
}

@media only screen and (max-width: 768px) {
	.logo_logo__XIK9M {
		width: 8.51669rem;
		height: 2.625rem;
		flex-shrink: 0;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/common/logo/logo.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,iBAAiB;CACjB,cAAc;AACf;;AAEA;CACC;EACC,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;CACf;AACD","sourcesContent":[".logo {\n\twidth: 9.125rem;\n\theight: 2.8125rem;\n\tflex-shrink: 0;\n}\n\n@media only screen and (max-width: 768px) {\n\t.logo {\n\t\twidth: 8.51669rem;\n\t\theight: 2.625rem;\n\t\tflex-shrink: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `logo_logo__XIK9M`
};
export default ___CSS_LOADER_EXPORT___;
